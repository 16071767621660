import React, {useLayoutEffect, useRef, useState} from 'react';
import {MDXProvider} from '@mdx-js/react';

import Layout from '@components/layout'
import SEO from '@components/seo'
import {Hero} from '@molecules/hero'
import LeftMenu from "@molecules/left-menu";
import Section from '@atoms/section'
import GoToTop from '@atoms/go-to-top'
import InlineItems from "@atoms/inline-items";
import ChapterTitle from "@atoms/chapter-title";
import sanitizeUrl from '@utils/sanitize-url';
import moveToElement from "@utils/move-to-element";
import {secureForEach, secureForEachNodes} from "@utils/secure-operations";

import './static-content-template.scss';

const COMPONENT_CLASS = 'staticContent chapterContent';
const components = {ChapterTitle, Section, InlineItems};

const StaticContentTemplate = ({data, children, ...props}) => {
    const [sidebarItems, setSidebarItems] = useState([]);
    const [movedToHash, setMovedToHash] = useState(false);
    const bodyContentRef = useRef(null);
    const pageData = props?.pageContext?.frontmatter;
    const title = pageData?.title;
    const description = pageData?.description;
    const leftMenu = pageData?.leftMenu;
    const seoTitle = `${title}${description ? ` - ${description}` : ''}`;

    const onClickSidebarLink = (clickedItem) => {
        if (!clickedItem) {
            return;
        }

        if (clickedItem && clickedItem.active) {
            return;
        }

        secureForEach(sidebarItems, item => item.active = false);

        clickedItem.active = true;
        const newItems = [...sidebarItems];
        setSidebarItems(newItems);
    };

    useLayoutEffect(() => {
        if (bodyContentRef && leftMenu) {
            const h2Items = bodyContentRef.current.querySelectorAll('h2');
            const items = [];

            secureForEachNodes(h2Items, item => {
                const text = item.innerText;
                const id = sanitizeUrl(text);
                item.setAttribute('id', id);
                const active = false;

                /* Make Scrollspy ids */
                const parentElement = item.parentElement;
                if (parentElement) {
                    parentElement.setAttribute('data-id', id);
                    parentElement.setAttribute('id', `section-${id}`);
                }

                items.push({
                    id,
                    text,
                    active,
                });
            });
            setSidebarItems(items);
        }
    }, [leftMenu, bodyContentRef]);

    useLayoutEffect(()=>{
        if (sidebarItems && sidebarItems.length) {
            moveToElement(movedToHash, setMovedToHash, function (element, currentHash) {
                if (element) {
                    const id = element.getAttribute('id');
                    const selectedItem = sidebarItems.find(item => item.id === id);

                    selectedItem && onClickSidebarLink(selectedItem);
                } else {
                    if (!currentHash) {
                        onClickSidebarLink(sidebarItems[0]);
                    }
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sidebarItems, movedToHash, setMovedToHash]);


    const content = <MDXProvider components={components}>
        {children}
    </MDXProvider>;

    return (
        <Layout className={COMPONENT_CLASS}>
            <SEO title={seoTitle}/>
            <Hero
                title={title}
                description={description}
            />
            <GoToTop/>
            {
                leftMenu &&
                <div ref={bodyContentRef}>
                    <Section noColumns={true} className="mt-4">
                        <div className={`col-lg-3 chapterContent__sidebar`}>
                            <div className="sidebar__items sticky-top">
                                <LeftMenu
                                    items={sidebarItems}
                                    onItemClick={(item) => onClickSidebarLink(item)} />
                            </div>
                        </div>
                        <div className={`col-lg-9 staticContent__body chapterContent__body`}>
                            {content}
                        </div>
                    </Section>
                </div>
            }
            {
                !leftMenu &&
                <Section className={'mt-5 staticContent__body'}>
                    {content}
                </Section>
            }

        </Layout>
    )
};

export default StaticContentTemplate;
