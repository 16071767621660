import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/diarcastro/clients/cure-foundation/looking-forward/src/templates/static-content-template.js";
import ExpertsList from '@organisms/experts-list';
import AboutLogos from "@organisms/about-logos";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InlineItems = makeShortcode("InlineItems");
const Section = makeShortcode("Section");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <section>
      <h2>{`About Looking Forward`}</h2>
      <p>{`As people with cancer finish active treatment and look towards returning to everyday life, it is very common for them to have questions about their recovery. There may also be uncertainty about next steps. Recovering from cancer treatment isn’t only about recuperation; it is also about healing—physically, emotionally, and spiritually. The `}<em parentName="p">{`Looking Forward`}</em>{` program provides support and resources to help recovering cancer patients rebuild their lives and move forward after treatment. Support and resources are also offered for the families and caregivers of patients.`}</p>
      <p>{`The `}<em parentName="p">{`Looking Forward`}</em>{` program strives to respect the uniqueness of each recovery experience. We consider recovery to be a process fundamentally based on personal experience. The program highlights topics and issues that patient collaborators have identified as being important in the recovery process, and helps patients identify where to focus their attention in their own recovery. It was vital to us that people who have experienced recovery be part of the collaboration with healthcare professionals and researchers in developing the `}<em parentName="p">{`Looking Forward`}</em>{` program. Former patients shared their perspectives, identified key priorities, and collaborated on the design and format. It is our firm belief that because of their engagement, this program will be a practical and relevant source of information.`}</p>
      <h3>{`Rosana Faria, L.Ps.`}</h3>
      <p>{`Principal Investigator, Dept. of Oncology, St. Mary’s Hospital Centre`}</p>
      <h3>{`Susan Law, Ph.D.`}</h3>
      <p>{`Co-Investigator, St. Mary’s Research Centre`}</p>
      <br />
    </section>
    <section>
      <h2>{`Acknowledgements`}</h2>
      <h3>{`Looking Forward interactive website`}</h3>
      <p>{`The `}<em parentName="p">{`Looking Forward`}</em>{` interactive website was made possible by generous funding from the CURE Foundation. We are so grateful to Diane Proulx-Guerrera and her team for understanding and supporting our vision for this resource. We would also like to thank Cynda Heward of St. Mary’s Foundation for her leadership in helping us propose the project.`}</p>
      <p>{`Many thanks to Dr. Mary Jane Esplen of the University of Toronto, Dr. Donna Stern of St. Mary’s Hospital and Dr. Luis Souhami of the MUHC for their time and expertise in reviewing content. Thank you also to Lowell Gasoi for his knowledge and skill in helping us develop content. To our consultant Mona Magalhaes—thank you for always being available to answer our questions. We are grateful to Céline Bailey for her translation of the content and to Maïa Magalhaes-Filion for her research skills and for proofing the French. We would also like to thank Linda Tracey for helping bring this project to life with the web team, for content development and for her overall coordination of the project.`}</p>
      <p>{`And last but not least, to our team at Evolving Web—Suzanne, Rakhi, Annika and Diego—we literally couldn’t have done it without you. We thank you for your creativity and artistry, your professionalism, and for being an absolute pleasure to work with.`}</p>
      <section className="books-container">
        <h3>{`Original booklet series`}</h3>
        <InlineItems className={'books'} mdxType="InlineItems">
          <p><img parentName="p" {...{
              "src": "/images/books/book-1.jpg",
              "alt": "book 1"
            }}></img></p>
          <p><img parentName="p" {...{
              "src": "/images/books/book-2.jpg",
              "alt": "book 2"
            }}></img></p>
          <p><img parentName="p" {...{
              "src": "/images/books/book-3.jpg",
              "alt": "book 3"
            }}></img></p>
          <p><img parentName="p" {...{
              "src": "/images/books/book-4.jpg",
              "alt": "book 4"
            }}></img></p>
          <p><img parentName="p" {...{
              "src": "/images/books/book-5.jpg",
              "alt": "book 5"
            }}></img></p>
          <p><img parentName="p" {...{
              "src": "/images/books/book-6.jpg",
              "alt": "book 6"
            }}></img></p>
          <p><img parentName="p" {...{
              "src": "/images/books/book-7.jpg",
              "alt": "book 7"
            }}></img></p>
        </InlineItems>
      </section>
      <p>{`We are grateful to the Rossy Cancer Network for their generous support in funding the original `}<em parentName="p">{`Looking Forward`}</em>{` program. The Rossy Cancer Network (RCN) is a partnership of McGill University’s Faculty of Medicine and its affiliated teaching hospitals: the McGill University Health Centre, the Jewish General Hospital and St. Mary’s Hospital Center. Launched in 2012, thanks to a transformative gift from the Rossy Family Foundation, the RCN is a 10-year initiative to improve quality across the continuum of cancer care for patients in the McGill-affiliated hospitals. Among the RCN’s key priorities is improving the patient experience throughout their cancer journey. The `}<em parentName="p">{`Looking Forward`}</em>{` initiative experience the transition to survivorship after treatment—a crucial phase in a patient’s cancer experience and one where more support is often required. The RCN is proud to support this initiative, which provides much-needed information and assistance to cancer patients who are adjusting to the ‘new normal’ in their lives following treatment.`}</p>
      <p>{`We would also like to acknowledge the generous financial support of our other sponsors of the original `}<em parentName="p">{`Looking Forward`}</em>{` program: the Canadian Institutes of Health Research and the User’s Committee of St. Mary’s Hospital Center. Special thanks to St. Mary’s Hospital Foundation for their ongoing support for research, education, and healthcare services. Thank you to St. Mary’s Research Centre for their continual support and to McGill University and the McGill University Health Centre.`}</p>
      <p>{`The `}<em parentName="p">{`Looking Forward`}</em>{` initiative would not have been possible without the patience, advice and support of patient collaborators who were research partners on this project. Particular thanks to Monique Ferland, Laurie Hendren, George Michaels, Danielle Potas, and Givette Volet for their feedback and invaluable insight. Thank you to the healthcare professional members of the `}<em parentName="p">{`Looking Forward`}</em>{` research team: Rosana Faria (clinical psychologist in Oncology at St. Mary’s Hospital), Susan Law (principal scientist at St. Mary’s Research Centre), Tarek Hijal (radiation oncologist at the MUHC), and Joan Zidulka (oncologist at St. Mary’s Hospital). Thanks also to Mona Magalhaes (project coordinator) and Maud Mazaniello (bilingual communications specialist). Sincere thanks to Linda Tracey for her editing and layout expertise in the production of the booklets, and to Céline Bailey for editing the French version. We are grateful to Joel Montanez and William Langlais for their professional and technical expertise in the production of the introductory video. Thank you to Dr. Sylvie Lambert for sharing with us her work on the `}<em parentName="p">{`Coping Together`}</em>{` self-management intervention that addresses the psychosocial needs of couples affected by cancer. The format for `}<em parentName="p">{`Looking Forward`}</em>{` was greatly inspired by this series.`}</p>
      <p>{`We owe a debt of gratitude to the patients who shared their experiences of treatment and life after treatment with us, and who worked with the healthcare professionals and research teams to select the topics included in the `}<em parentName="p">{`Looking Forward`}</em>{` program. We feel honoured to have heard your stories. We are also grateful to the healthcare professionals for sharing their experiences of caring for people with cancer.`}</p>
      <p>{`Our heartfelt thanks go to the oncology professionals who supported the development of materials and who offered their expert advice: Karine Gimmig (Cancer Care, SMHC), Marc Hamel (Psychosocial Oncology, MUHC), Sandra Grant (Cancer Care, SMHC), Nancy Moscato (social worker, MUHC), Maria Milioto (Cliniques d’Évaluation et de Réadaption), Janet Steinberg (Cancer Care, SMHC), Mei-Lin Yee (patient rights advocate) and Joan Zidulka (Oncology, St. Mary’s Hospital).`}</p>
      <AboutLogos mdxType="AboutLogos" />
    </section>
    <section>
      <h2>{`Meet the experts`}</h2>
      <Section className="mt-6" mdxType="Section">
    <ExpertsList mdxType="ExpertsList" />
      </Section>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      