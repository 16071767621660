import React from 'react';
import PropTypes from 'prop-types';

import './chapter-title.scss';

const ChapterTitle = ({ chapter, title, subtitle }) => {

    return (
        <article className={'chapter-title'}>
            <h2 className={'font-weight-bold'}>{chapter}</h2>
            <div className="title">
                <h2 className={'font-weight-bold'}>{title}</h2>
                <h3 className={'font-weight-bold'}>{subtitle}</h3>
            </div>
        </article>
    );
};


ChapterTitle.propTypes = {
    chapter: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,

};

ChapterTitle.defaultProps = {
};

export default ChapterTitle;
